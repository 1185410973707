<template>

<app-content-box :class="{'is-empty': !event}" title="Your next event" :is-blue="!event" icon="time" class="next" :not-padded="true" :flex-grow="true">

	<template v-if="event">

	<router-link :to="{name: 'Convention.Schedule.Event', params: { id: event.id }}" class="about-description">
		
		<div class="about-description-background" v-bgimage="image"></div>

	</router-link>

	<router-link :to="{name: 'Convention.Schedule.Event', params: { id: event.id }}" class="about-name">

		{{ event.name }} is next up

	</router-link>

	<div class="about-terms">

		<div class="about-terms-item">
			<div class="about-terms-item-label">On</div>
			<div class="about-terms-item-value">{{ event.date | formatDate('dddd') }}</div>
		</div>

		<div class="about-terms-item">
			<div class="about-terms-item-label">At</div>
			<div class="about-terms-item-value">{{ event.date | formatDate($hourFormat) }}</div>
		</div>

		<div class="about-terms-item">
			<div class="about-terms-item-label">For</div>
			<div class="about-terms-item-value">{{ event.duration | hours }} hours</div>
		</div>

		<div class="about-terms-item">
			<div class="about-terms-item-label">In</div>
			<div class="about-terms-item-value">{{ event.location }}</div>
		</div>

	</div>

	</template>

	<div class="next-empty" v-if="!event && $loggedin">

		You have no upcoming events.

	</div>

	<div class="next-empty" v-if="!event && !$loggedin">

		<p>Login to join events.</p> <app-link theme="white" :route="{name: 'Login'}" text="Login" />

	</div>

</app-content-box>

</template>

<script>

export default {

	props: ['next'],

	computed: {

		image: function() {

			if (this.event.image) return this.event.image 
			if (this.event.game) return this.event.game 

			return this.$convention.logo

		},

		event: function() {

			return (this.next.length) ? this.next[0] : false

		}

	}

}

</script>

<style scoped>

.next {
	display: flex;
	flex-direction: column;
	min-height: 400px;
}

.about-description {
	padding: 40px;
	border-bottom: 1px solid #eee;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.is-tablet .about-description {
	padding: 20px;
}

.is-mobile .about-description {
	padding: 20px;
}

.about-description-background {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-position: 50% 50%;
	background-size: cover;
}

.about-description.has-text .about-description-background {
	filter: grayscale(100%);
	opacity: 0.2;
}

.about-description-text {
	font-size: 24px;
	font-weight: 400;
	line-height: 32px;
	color: #687589;
}

.about-description-icon {
    margin-right: 10px;
	font-size: 64px;
	float: left;
}

.about-description-host {
	align-self: flex-end;
	display: flex;
	flex-direction: row;
}

.about-description-host-avatar {
	width: 36px;
	height: 36px;
	border-radius: 4px;
	background-color: #eee;
}

.about-description-host-avatar {
	line-height: 36px;
	text-align: center;
	font-size: 16px;
	color: #999;
}

.about-description-host-name {
	font-size: 20px;
	color: #687589;
	margin-left: 12px;
}

.about-description-host-name small {
	font-size: 14px;
	display: block;
	margin-bottom: 2px;
}

.about-name {
	font-size: 20px;
	font-weight: 500;
	padding: 20px;
	color: #fff;
	background-color: #4082d3;
}

.is-mobile .about-name,
.is-tablet .about-name {
	font-size: 16px;
	padding: 10px;
}

.about-terms {
	display: flex;
}

.is-mobile .about-terms {
	flex-wrap: wrap;
}

.about-terms-item {
	padding: 20px;
	flex-grow: 1;
	flex-shrink: 0;
}

.is-mobile .about-terms-item {
	width: 50%;
	padding: 10px;
}

.is-tablet .about-terms-item {
	padding: 10px;
}

.about-terms-item-label {
	font-size: 14px;
	color: #687589;
	margin-bottom: 2px;
}

.about-terms-item-value {
	font-size: 20px;
	color: #687589;
}

.is-mobile .about-terms-item-value,
.is-tablet .about-terms-item-value {
	font-size: 16px;
}

.next-empty {
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	padding: 20px;
	position: absolute;
	left: 50%;
	white-space: nowrap;
	top: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
}

.is-mobile .next-empty {
	font-size: 20px;
}

.next-empty p {
	margin-bottom: 20px;
}

</style>